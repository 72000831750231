import Link from 'next/link'
import { Link as AriaLink, LinkProps as AriaLinkProps, PressEvent } from 'react-aria-components'
import styled, { css } from 'styled-components'
import { BREAKPOINTS } from '../breakpoints'

const StyledLinkButton = styled(AriaLink)<{ $mobileWidth?: 'full' | 'auto' }>`
  // Should match the DS Button component
  border: 0;
  border-radius: var(--radius-8);
  display: inline-flex;
  font-family: var(--font-sans-serif-regular);
  justify-content: center;
  transition: background-color 0.1s ease-in-out;

  &:focus-visible {
    outline: 0px;
  }

  &[data-hovered] {
    cursor: pointer;
  }

  &[data-disabled] {
    opacity: 0.5;
  }

  &.primary {
    background-color: var(--color-surface-button-primary);
    color: var(--color-text-button-primary);

    &[data-hovered] {
      background-color: var(--color-surface-button-primary-hover);
      color: var(--color-text-button-primary-hover);
    }

    &.inverse {
      background-color: var(--color-surface-button-primary-inverse);
      color: var(--color-text-button-primary-inverse);

      &[data-hovered] {
        background-color: var(--color-surface-button-primary-inverse-hover);
        color: var(--color-text-button-primary-inverse-hover);
      }
    }
  }

  &.secondary {
    background-color: var(--color-surface-button-secondary);
    border: var(--border-width-button-secondary) solid var(--color-border-button-secondary);
    color: var(--color-text-button-secondary);

    &[data-hovered] {
      background-color: var(--color-surface-button-secondary-hover);
      border: var(--border-width-button-secondary-hover) solid var(--color-surface-button-secondary-hover);
      color: var(--color-text-button-secondary-hover);
      span {
        color: var(--color-text-button-secondary-hover);
      }
    }

    &.inverse {
      background-color: var(--color-surface-button-secondary-inverse);
      border: var(--border-width-button-secondary) solid var(--color-border-button-secondary-inverse);
      color: var(--color-text-button-secondary-inverse);

      &[data-hovered] {
        background-color: var(--color-surface-button-secondary-inverse-hover);
        border: var(--border-width-button-secondary-hover) solid var(--color-surface-button-secondary-inverse-hover);
        color: var(--color-text-button-secondary-inverse-hover);
        span {
          color: var(--color-text-button-secondary-hover);
        }
      }
    }
  }

  &.tertiary {
    background-color: var(--transparent);
    border-radius: 0;
    color: var(--color-text-button-tertiary);

    &[data-hovered] {
      color: var(--color-text-button-tertiary-hover);
    }

    &.large,
    &.small {
      padding: 0;
    }
  }

  &.large {
    font-size: var(--font-size-button-large);
    line-height: var(--line-height-button-large);
    padding: var(--padding-button-large-y) var(--padding-button-large-x);
  }

  &.small {
    font-size: var(--font-size-button-small);
    line-height: var(--line-height-button-small);
    padding: var(--padding-button-small-y) var(--padding-button-small-x);
  }

  &.full {
    width: 100%;
  }

  &.rounded {
    border-radius: var(--radius-full);
  }

  ${({ $mobileWidth }) =>
    $mobileWidth &&
    css`
      @media screen and (max-width: ${BREAKPOINTS.m}) {
        width: ${$mobileWidth === 'full' ? '100%' : 'auto'};
      }
    `}
`

interface CustomLinkButtonProps extends AriaLinkProps {
  variant: 'primary' | 'secondary' | 'tertiary'
  colourScheme: 'default' | 'inverse'
  size: 'large' | 'small'
  width: 'full' | 'auto'
  mobileWidth?: 'full' | 'auto'
  href: string
  children: React.ReactNode
  disabled?: boolean
  target?: '_blank' | '_self' | '_parent' | '_top'
  testId?: string
  dangerouslySet__rounded?: boolean
  onPress?: (event: PressEvent) => void
}

// https://react-spectrum.adobe.com/react-aria/Link.html

/*  React Aria Link does not accept onClick (using instead onPress). 
    https://react-spectrum.adobe.com/blog/building-a-button-part-1.html#building-a-button
    
    However, NextJS Link children must implement an onClick event handler. 
    This causes console warnings at the moment but behaviour seems to be working.
    https://nextjs.org/docs/app/api-reference/components/link#if-the-child-is-a-custom-component-that-wraps-an-a-tag
*/

export const DSLinkButton = (props: CustomLinkButtonProps) => {
  const rounded = props.dangerouslySet__rounded ? 'rounded' : undefined

  const classNames = `${props.variant} ${props.colourScheme} ${props.size} ${props.width} ${rounded}`

  const handleOnPress = (event: PressEvent) => {
    if (!props.disabled && props.onPress) {
      props.onPress(event)
    }
  }

  return (
    <Link href={props.href} passHref legacyBehavior>
      <StyledLinkButton
        className={`${classNames}`}
        target={props.target || '_self'}
        data-testid={props.testId}
        isDisabled={props.disabled}
        onPress={handleOnPress}
        $mobileWidth={props.mobileWidth}
        aria-label={props['aria-label']}
      >
        {props.children}
      </StyledLinkButton>
    </Link>
  )
}
