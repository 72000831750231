import { DSLinkText } from '@/components/ds/LinkText/LinkText'
import {
  DSDivider,
  DSText,
  DSTextEm,
  DSTextStrong,
  BREAKPOINTS,
  DSBreakpointType,
  DSTextFontFamilyType,
} from '@zoe/ds-web'
import { FragmentComponent } from '@/types/graphql'
import React, { ReactNode } from 'react'
import { TypographyRecord } from 'types'
import { TypographyIcon } from '@/blocks/DesignSystem/TypographyIcon'

const fragment = `
  fragment TypographyBlockFragment on TypographyRecord {
    __typename
    id
    content
    colour
    htmlTag
    elementId
    variant
    mobileVariant
    weight
    alignment
    mobileAlignment
  }
`

const translateOldWeightToNewWeight = (weight: string): DSTextFontFamilyType => {
  switch (weight) {
    case 'lotaLight':
      return 'light'
    case 'lotaRegular':
      return 'regular'
    case 'lotaSemiBold':
      return 'semiBold'
    case 'nantes':
      return 'book'
    case 'nantesItalic':
      return 'bookItalic'
    default:
      return weight as DSTextFontFamilyType
  }
}

/**
 * Replaces "\n" with line breaks in the input string
 */
const handleNewlines = (text: string): ReactNode[] => {
  return text
    .split('\n')
    .flatMap((line, index, array) => [line, ...(index < array.length - 1 ? [<br key={`br-${line}-${index}`} />] : [])])
}

/**
 * Returns the input string with the following sub-strings replaced by their respective components:
 * 1. "*text*" -> DSTextEm
 * 2. "**text**" -> DSTextStrong
 * 3. "[text](url)" -> DSLinkText
 * 4. "\n" -> <br />
 * 5. "{{icon:iconName:size}}" -> DSIcon name={iconName} size={size}
 */
export const processTypographyMarkdown = ({ content, ...props }): ReactNode[] => {
  const markdownRegex =
    /(\*\*)([^\*\n]+?)\1|(\*)([^\*\n]+?)\3|\[(.*?)\]\((.*?)\)|{{icon:(.*?)(:([\d.]+))?}}|{{divider}}/g
  const parts: ReactNode[] = []

  let lastIndex = 0

  content?.replace(
    markdownRegex,
    (match, _p1, boldText, _p3, italicText, linkText, linkUrl, iconName, _, iconSize, offset, divider) => {
      // Add the plain text before the match
      if (offset > lastIndex) {
        const textBeforeMatch = content.slice(lastIndex, offset)
        parts.push(...handleNewlines(textBeforeMatch))
      }

      const commonProps = {
        variant: props.variant,
        color: props.colour,
        weight: translateOldWeightToNewWeight(props.weight),
        align: props.alignment,
        breakAt: {
          breakPoint: BREAKPOINTS.m as DSBreakpointType,
          variant: props.mobileVariant || props.variant,
          align: props.mobileAlignment || props.alignment,
        },
        id: props.elementId,
        className: props.className,
      }

      if (boldText) {
        // Bold (**text**)
        parts.push(
          <DSTextStrong key={parts.length} as="span" {...commonProps}>
            {boldText}
          </DSTextStrong>,
        )
      } else if (italicText) {
        // Italic (*text*)
        parts.push(
          <DSTextEm key={parts.length} as="span" {...commonProps}>
            {italicText}
          </DSTextEm>,
        )
      } else if (linkText && linkUrl) {
        // Link ([text](url))
        parts.push(
          <DSLinkText href={linkUrl} key={parts.length} target="_blank" className={props.className}>
            {linkText}
          </DSLinkText>,
        )
      } else if (iconName) {
        parts.push(<TypographyIcon key={parts.length} name={iconName} size={iconSize} />)
      } else if (match === '{{divider}}') {
        parts.push(
          <div style={{ display: 'inline-block', position: 'relative', top: '10px' }}>
            <DSDivider variant="primary" direction="vertical" height={32} spacing={24} />
          </div>,
        )
      }

      lastIndex = offset + match.length
      return match
    },
  )

  if (lastIndex < content?.length) {
    const remainingText = content.slice(lastIndex)
    parts.push(...handleNewlines(remainingText))
  }

  return parts
}

export const TypographyBlock: FragmentComponent<{}, TypographyRecord> = ({ record }) => {
  return (
    <DSText
      variant={record?.variant}
      color={record?.colour}
      as={record?.htmlTag}
      weight={translateOldWeightToNewWeight(record?.weight)}
      align={record?.alignment}
      breakAt={{
        breakPoint: 'm',
        variant: record?.mobileVariant || record?.variant,
        align: record?.mobileAlignment || record?.alignment,
      }}
      id={record?.elementId}
    >
      {processTypographyMarkdown(record)}
    </DSText>
  )
}

TypographyBlock.fragment = fragment
TypographyBlock.recordName = 'TypographyRecord'
