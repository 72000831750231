import styled from 'styled-components'
import { DSGridSpacing } from '@zoe/ds-web'
import { Breakpoint, BREAKPOINTS } from '@/components/ds/breakpoints'

const StyledFlex = styled.div<{
  $direction?: FlexDirection
  $breakConfig?: BreakConfig
  $alignContent?: string
  $alignItems?: string
  $gap?: DSGridSpacing
  $height?: string
  $justifyContent?: string
  $width?: string
  $wrap?: string
  $flex?: string
}>`
  display: flex;

  ${({ $direction }) => $direction && `flex-direction: ${$direction};`}
  ${({ $alignContent }) => $alignContent && `align-content: ${$alignContent};`}
  ${({ $alignItems }) => $alignItems && `align-items: ${$alignItems};`}
  ${({ $height }) => $height && `height: ${$height};`}
  ${({ $gap }) => $gap && `gap: var(--grid-${$gap});`}
  ${({ $height }) => $height && `height: ${$height};`}
  ${({ $justifyContent }) => $justifyContent && `justify-content: ${$justifyContent};`}
  ${({ $width }) => $width && `width: ${$width};`}
  ${({ $wrap }) => $wrap && `wrap: ${$wrap};`}
  ${({ $flex }) => $flex && `flex: ${$flex};`}

  ${({ $breakConfig }) =>
    $breakConfig &&
    `
      flex-direction: ${$breakConfig.maxViewportDirection};

      @media screen and (max-width: ${BREAKPOINTS[$breakConfig.breakAt.breakPoint]}) {
        align-items: ${$breakConfig.breakAt.alignItems ?? 'inherit'};
        gap: ${($breakConfig.breakAt.gap ?? 0) === 0 ? '' : `var(--grid-${$breakConfig.breakAt.gap})`};

        ${$breakConfig.breakAt.direction && `flex-direction: ${$breakConfig.breakAt.direction};`}
        ${$breakConfig.breakAt.alignContent && `align-content: ${$breakConfig.breakAt.alignContent};`}
        ${$breakConfig.breakAt.justifyContent && `justify-content: ${$breakConfig.breakAt.justifyContent};`}
      }
  `}
`

type FlexDirection = 'row' | 'column' | 'row-reverse' | 'column-reverse'
type FlexAlignContent = 'center' | 'flex-start' | 'flex-end' | 'space-between' | 'space-around' | 'stretch'
export type FlexAlignItems = 'center' | 'flex-start' | 'flex-end' | 'baseline' | 'stretch'
type FlexJustifyContent = 'center' | 'flex-start' | 'flex-end' | 'space-between' | 'space-around' | 'space-evenly'
type FlexWrap = 'wrap' | 'nowrap'

type BreakConfig = {
  maxViewportDirection: FlexDirection
  breakAt: {
    breakPoint: Breakpoint
    direction: FlexDirection
    gap?: DSGridSpacing
    alignContent?: FlexAlignContent
    alignItems?: FlexAlignItems
    justifyContent?: FlexJustifyContent
  }
}
interface FlexPropsBase {
  children: React.ReactNode
  alignContent?: FlexAlignContent
  alignItems?: FlexAlignItems
  gap?: DSGridSpacing
  height?: string // e.g 30px or 100%
  justifyContent?: FlexJustifyContent
  width?: string // e.g 30px or 100%
  wrap?: FlexWrap
  flex?: string
}

interface FlexPropsDirection extends FlexPropsBase {
  direction: FlexDirection
  breakConfig?: never
}

interface FlexPropsBreak extends FlexPropsBase {
  breakConfig: BreakConfig
  direction?: never
}

type FlexProps = FlexPropsDirection | FlexPropsBreak

export const DSFlex = ({
  direction,
  breakConfig,
  children,
  alignContent,
  alignItems,
  gap,
  height,
  justifyContent,
  width,
  wrap,
  flex,
}: FlexProps) => {
  return (
    <StyledFlex
      $direction={direction}
      $breakConfig={breakConfig}
      $alignContent={alignContent}
      $alignItems={alignItems}
      $gap={gap}
      $height={height}
      $justifyContent={justifyContent}
      $width={width}
      $wrap={wrap}
      $flex={flex}
    >
      {children}
    </StyledFlex>
  )
}
