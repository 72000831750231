import { ButtonProps as AriaButtonProps, Button } from 'react-aria-components'
import styled from 'styled-components'

const StyledButton = styled(Button)`
  // Should match the DS Link component
  border: 0;
  border-radius: var(--radius-8);
  display: inline-flex;
  font-family: var(--font-sans-serif-regular);
  justify-content: center;
  align-items: center;
  transition: background-color 0.1s ease-in-out;

  &:focus-visible {
    outline: 0px;
  }

  &[data-hovered] {
    cursor: pointer;
  }

  &[data-disabled] {
    opacity: 0.5;
  }

  &.primary {
    background-color: var(--color-surface-button-primary);
    color: var(--color-text-button-primary);

    &[data-hovered] {
      background-color: var(--color-surface-button-primary-hover);
      color: var(--color-text-button-primary-hover);
      span,
      p {
        color: var(--color-text-button-primary-hover);
      }
    }

    &.inverse {
      background-color: var(--color-surface-button-primary-inverse);
      color: var(--color-text-button-primary-inverse);

      &[data-hovered] {
        background-color: var(--color-surface-button-primary-inverse-hover);
        color: var(--color-text-button-primary-inverse-hover);
        span,
        p {
          color: var(--color-text-button-primary-inverse-hover);
        }
      }
    }
  }

  &.secondary {
    background-color: var(--color-surface-button-secondary);
    border: var(--border-width-button-secondary) solid var(--color-border-button-secondary);
    color: var(--color-text-button-secondary);

    &[data-hovered] {
      background-color: var(--color-surface-button-secondary-hover);
      border: var(--border-width-button-secondary-hover) solid var(--color-surface-button-secondary-hover);
      color: var(--color-text-button-secondary-hover);
      span,
      p {
        color: var(--color-text-button-secondary-hover);
      }
    }

    &.inverse {
      background-color: var(--color-surface-button-secondary-inverse);
      border: var(--border-width-button-secondary) solid var(--color-border-button-secondary-inverse);
      color: var(--color-text-button-secondary-inverse);

      &[data-hovered] {
        background-color: var(--color-surface-button-secondary-inverse-hover);
        border: var(--border-width-button-secondary-hover) solid var(--color-surface-button-secondary-inverse-hover);
        color: var(--color-text-button-secondary-inverse-hover);
        span,
        p {
          color: var(--color-text-button-secondary-inverse-hover);
        }
      }
    }
  }

  &.tertiary {
    background-color: var(--transparent);
    border-radius: 0;
    color: var(--color-text-button-tertiary);

    &[data-hovered] {
      color: var(--color-text-button-tertiary-hover);
      span,
      p {
        color: var(--color-text-button-tertiary-hover);
      }
    }

    &.large,
    &.small {
      padding: 0;
    }
  }

  &.large {
    font-size: var(--font-size-button-large);
    line-height: var(--line-height-button-large);
    padding: var(--padding-button-large-y) var(--padding-button-large-x);
  }

  &.small {
    font-size: var(--font-size-button-small);
    line-height: var(--line-height-button-small);
    padding: var(--padding-button-small-y) var(--padding-button-small-x);
  }

  &.full {
    width: 100%;
  }

  &.rounded {
    border-radius: var(--radius-full);
  }

  // If mobile styling needed, make sure to add to buttonStyles.ts, not here
`

export type DSButtonVariant = 'primary' | 'secondary' | 'tertiary'
export type DSButtonColourScheme = 'default' | 'inverse'
export type DSButtonSize = 'large' | 'small'
export type DSButtonWidth = 'full' | 'auto'

interface CustomButtonProps extends AriaButtonProps {
  variant: DSButtonVariant
  colourScheme: DSButtonColourScheme
  size: DSButtonSize
  width: DSButtonWidth
  children: React.ReactNode
  dangerouslySet__rounded?: boolean
  testId?: string
}

// https://react-spectrum.adobe.com/react-aria/Button.html

export const DSButton = ({
  variant,
  colourScheme,
  size,
  width,
  children,
  dangerouslySet__rounded,
  ...props
}: CustomButtonProps) => {
  const rounded = dangerouslySet__rounded ? 'rounded' : undefined

  return (
    <StyledButton
      {...props}
      className={`${variant} ${colourScheme} ${size} ${width} ${rounded}`}
      data-testid={props.testId}
    >
      {children}
    </StyledButton>
  )
}
