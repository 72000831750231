import Link from 'next/link'
import { Link as AriaLink, LinkProps as AriaLinkProps, PressEvent } from 'react-aria-components'
import styled from 'styled-components'

const StyledLinkText = styled(AriaLink)`
  .default {
    color: var(--color-text-link);
  }

  &[data-hovered] {
    color: var(--color-text-link-hover);
    cursor: pointer;
  }

  &.inverse {
    color: var(--color-text-link-inverse);

    &[data-hovered] {
      color: var(--color-text-link-inverse-hover);
    }
  }

  &.navigation {
    color: var(--color-text-link-navigation-bold);

    &[data-hovered] {
      color: var(--color-text-link-navigation-bold-hover);
    }
  }

  &.inherit {
    color: inherit;
    text-decoration: underline;

    &[data-hovered] {
      color: inherit;
    }
  }
`

// Hint: If you need to render different text variants, try wrapping this component in a DSText component.
interface CustomLinkTextProps extends AriaLinkProps {
  href: string
  children: React.ReactNode
  colourScheme?: 'default' | 'inherit' | 'inverse' | 'navigation'
  target?: '_blank' | '_self' | '_parent' | '_top'
  testId?: string
  onPress?: (event: PressEvent) => void
}

// https://react-spectrum.adobe.com/react-aria/Link.html

/*  React Aria Link does not accept onClick (using instead onPress).
    https://react-spectrum.adobe.com/blog/building-a-button-part-1.html#building-a-button

    However, NextJS Link children must implement an onClick event handler.
    This causes console warnings at the moment but behaviour seems to be working.
    https://nextjs.org/docs/app/api-reference/components/link#if-the-child-is-a-custom-component-that-wraps-an-a-tag
*/

export const DSLinkText = (props: CustomLinkTextProps) => {
  const colourScheme = props.colourScheme || 'default'

  const handleOnPress = (event: PressEvent) => {
    if (props.onPress) {
      props.onPress(event)
    }
  }

  return (
    <Link href={props.href} passHref legacyBehavior>
      <StyledLinkText
        className={`${colourScheme}`}
        target={props.target || '_self'}
        data-testid={props.testId}
        onPress={handleOnPress}
      >
        {props.children}
      </StyledLinkText>
    </Link>
  )
}
